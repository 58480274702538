.m_b37d9ac7 {
  width: calc(100% - var(--mantine-spacing-md) * 2);
  position: fixed;
  z-index: var(--notifications-z-index);
  max-width: var(--notifications-container-width);
}

  .m_b37d9ac7:where([data-position='top-center']) {
    top: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='top-left']) {
    top: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='top-right']) {
    top: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-center']) {
    bottom: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='bottom-left']) {
    bottom: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-right']) {
    bottom: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

.m_5ed0edd0 + .m_5ed0edd0 {
    margin-top: var(--mantine-spacing-md);
  }
